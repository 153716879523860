import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/loader/index";

const LandingPage = lazy(() => import("../pages/home"));

export default function AppRoutes() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
      </Routes>
    </React.Suspense>
  );
}
