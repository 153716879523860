import { ConfigProvider } from "antd";
import "./App.css";
import Router from "./routes/index";
import { useEffect } from "react";
import "antd/dist/antd.variable.min.css";

function App() {
  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: "#32cd32",
      },
    });
  }, []);
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
